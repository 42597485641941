import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import usePageLoading from 'components/utils/usePageLoading'

const Blank = ({ data }) => {

  return (
    <div>
      Blank page
    </div>
  )
}
export default Blank
